import React, { Component } from 'react';
import { Menu, Icon,Badge } from 'antd';

import ToolbarMenu from '../MainHor/ToolbarMenu';


export default class UserToolbar extends ToolbarMenu {

  handleClick = (e) => {
      window.sessionStorage.isLogin='false'
      window.location.hash = '/login'

  };

  render() {
    return (<div>
      <Menu mode="horizontal" theme="dark" onClick={this.handleClick} selectedKeys={[this.props.current]}>
            <Menu.Item key="logout">注销</Menu.Item>
      </Menu>
    </div>
    )
  }
}