import React, { Component, Suspense, lazy } from 'react';
import './App.less';
import './utils/Utils.js';
import './utils/RaSDK';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import zhCN from 'antd/lib/locale-provider/zh_CN';
import { LocaleProvider } from 'antd';

import MainHor from './layout/MainHor';
import Login from './pages/account/login';
import Design from './pages/account/design';
import RedirectPage from './pages/account/login/Redirect';
import Store from './store/store.js';

import * as utils from './utils/Utils';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    utils.getCookie('isLogin') === 'true'
      // window.sessionStorage.isLogin === 'true'
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)

export default class App extends Component {
  render() {
    return (
      <LocaleProvider locale={zhCN}>
        <Router>
          <Suspense fallback={<div>加载中...</div>}>
            <Switch>
              <Route exact path='/login' component={Login} />
              <Route path='/design' component={Design} />
              <PrivateRoute path='/' component={
                () => (
                  <MainHor>
                    {
                      Store.getRoutes()
                    }
                  </MainHor>
                )}
              />
            </Switch>
          </Suspense>
        </Router>
      </LocaleProvider>
    );
  }
}