import React, { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { getPermission, getTenant, getTenantType } from '../utils/authority';

 //const local_url = 'http://localhost:3001/tv.html/';
const online_url = 'https://i.rmes.cn/tv.html/';
const url = window.location.protocol +'//'+window.location.host + '/tv.html';

const menus = [
	// // { name: '首页', icon: 'home', key: 'index', url: '/' },
	// { name: '报表管理',key:'report', url: '/report' },
]

function getMenuUrlKeyMap(menus) {
	let res = {};
	menus.forEach(item => {
		if (!!item.url) {
			res[item.url] = item.key;
		}
		if (!!item.child) {
			Object.assign(res, getMenuUrlKeyMap(item.child));
		}
	})
	return res;
}

function getFilterMenuUrlKeyMap() {
	let res = {};
	filterPermission(menus).forEach(item => {
		if (!!item.url) {
			res[item.url] = item.key;
		}
		if (!!item.child) {
			Object.assign(res, getMenuUrlKeyMap(item.child));
		}
	})
	return res;
}

function filterPermission(menus) {
	//TODO 临时去掉权限
	// return menus;
	const permissionList = getPermission();
	if (!permissionList)
		return [];
	let childrenIndex = 0;
	//克隆数据，不修改默认的menus对象
	let tmpMenus = JSON.parse(JSON.stringify(menus));
	for (let i = 0; i < tmpMenus.length; i++) {
		if (tmpMenus[i].child) {
			tmpMenus[i].child = filterPermission(tmpMenus[i].child);
			let permissionMenu = permissionList.find(item => item.key === tmpMenus[i].key);
			if ((!tmpMenus[i].child || tmpMenus[i].child.length == 0) && !permissionMenu) {
				tmpMenus.splice(i, 1)
				i--;
			}
		} else {
			if ('index' === tmpMenus[i].key) {
				continue;
			}
			let isCustomShow = false;
			//处理定制菜单
			if (!!tmpMenus[i].custom) {
				let custom = tmpMenus[i].custom;
				let tenantId = getTenant();
				let tenantType = getTenantType();
				if (!!custom) {
					if (!!custom.tenant && !!custom.tenant[tenantId]) {
						let tenantCustom = custom.tenant[tenantId];
						if ('visible' === tenantCustom.type) {
							isCustomShow = true;
						} else if ('replace' === tenantCustom.type) {
							Object.keys(tenantCustom.detail).forEach(key => {
								tmpMenus[i][key] = tenantCustom.detail[key];
							})
						}
					}
					if (!!custom.tenantType && !!custom.tenantType[tenantType]) {
						let tenantTypeCustom = custom.tenantType[tenantType];
						if('replace' === tenantTypeCustom.type){
							Object.keys(tenantTypeCustom.detail).forEach(key => {
								tmpMenus[i][key] = tenantTypeCustom.detail[key];
							})
						}
					}
				}
			}
			const t = permissionList.filter(permission => permission.key === tmpMenus[i].key)
			if (!isCustomShow && (!t || t.length === 0)) {
				tmpMenus.splice(i, 1);
				i--;
			}
		}
	}
	return tmpMenus;
	// return menus;
}

function handleIndex(menus) {
	let childrenIndex = 0;
	for (let i = 0; i < menus.length; i++) {
		if (menus[i].child) {
			handleIndex(menus[i].child);
		} else {
			if (menus[i].name.indexOf(")") === 0) {
				menus[i].name = ++childrenIndex + menus[i].name;
			}
		}
	}
}

function getMenus(key) {
	let filterMenus = filterPermission(menus);
	handleIndex(filterMenus);
	if (!!key) {
		filterMenus = filterMenus.filter(item => item.key === key);
	}
	return filterMenus;
}

function getRoutes() {
	const routers = {
		'index': {
			component: lazy(() => import('../component-biz/BizFlow'))
		}
	}
	let filterMenus = filterPermission(menus);
	let routes = [];
	filterMenus.map(menu => {
		if (!!routers[menu.key]) {
			let component = routers[menu.key].component;
			if (!!component) {
				if ('index' === menu.key) {
					routes.push(<Route exact path={menu.url} component={AsyncComponent(component)} />)
				} else {
					routes.push(<Route exact path={menu.url} component={AsyncComponent(component)} />)
				}
			}
		}
		if (!!menu.child && menu.child.length > 0) {
			menu.child.map(childMenu => {
				if (!!routers[childMenu.key]) {
					let component = routers[childMenu.key].component;
					routes.push(<Route path={childMenu.url} component={AsyncComponent(component)} />)
				}
			})
		}
	})
	return routes;
}

export default {
	getMenus,
	getRoutes,
	getUrlKeyMap: getMenuUrlKeyMap(menus),
	getFilterMenuUrlKeyMap
}

const AsyncComponent = Component => props => (
	<React.Suspense fallback={<div>loading....</div>}>
		{/* <div style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, display: 'flex', padding: '8px', margin: 13 }}> */}
		<Component {...props} />
		{/* </div> */}
	</React.Suspense>
)
