import * as React from "react";
import * as utils from "../../../utils/Utils";

export default class Redirect extends React.Component {

    componentDidMount() {
        let hash = window.location.hash;
        let index = hash.indexOf('redirect?');
        if (index<0) {
            return;
        }

        let url = hash.substring(index + 8);

        let redirectUrl = this.getQueryString(url, 'url');
        let userUrl = this.getQueryString(url, 'token');
        console.log("重定向："+redirectUrl)
        window.rmes.get(userUrl, (result)=>{
            delete result.permissions;
            let user = JSON.stringify(result);

            //session 無法直接通过cookie获取session，因此在这里存储
            window.sessionStorage.session = result.session;

            let proxyCookieDomain = result.tenantDetail.cookieDomain;
            utils.setCookie('isLogin', true, proxyCookieDomain);
            utils.setCookie('user', user,proxyCookieDomain);
            utils.setCookie('username', user.name, proxyCookieDomain);

            if (
                !!result.tenantDetail.tenantVersionUrl
                // && result.tenantDetail.tenantVersionUrl.indexOf(window.location.host) < 0
            ) {
                //To load diffrent page by tenant version,reset loaction to domain address,trigger the browser refresh event to reload the index page.
                window.location = result.tenantDetail.tenantVersionUrl + '/#' + redirectUrl;
            } else {
                window.location.hash = redirectUrl | '/';
            }

            window.rmes.raLogin();
        });
    }

    render() {
        return null;
    }

    getQueryString=(url, name)=> {
        let reg = new RegExp("(^|&|/?)" + name + "=([^&]*)(&|$)", "i");
        let r = url.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    }

}
