import React, { Component } from 'react';
import { Tabs, Input, Tooltip, Icon, Button, Checkbox, Alert } from 'antd';
import './index.less';
const TabPane = Tabs.TabPane;

export default class Login extends Component {

    state = {
      username: window.localStorage.username,
      password: '',
      error: '',
      logining: false,
      type: 'account'
    }

  componentDidMount() {
    let backgroundDom = window.document.getElementById('splash')
    if (backgroundDom) {
      backgroundDom.style.opacity = 0
      backgroundDom.style.transition = 'opacity 2s'
      // backgroundDom.parentNode.removeChild(backgroundDom)
    }
    document.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e) => {
    if (13 === e.keyCode) {
        this.loginPwd()
    }
  }

  onTabChange = (type) => {
    this.setState({ type });
  }

  onIframeLoad = () => {
    this.setState({
      loading: false
    })
  }

  onIframeError = () => {
    window.rmes.message.warn("加载错误")
  }

  render() {

    let { username, password, error, logining, url } = this.state

    return (<div className="r-account-login" >
        <div className="background" />
        <div style={{ height: '10%' }} />
    <div style={{ textAlign: 'center' }}>
  <div className='mainTitle'>RMES-报表管理系统</div>
        <div className='subTitle'>RMES 是广联达旗下迈思科技专为钢筋加工过程管理打造的软硬一体化解决方案</div>
    <Divider height={30} />
    </div>
    <div className='loginPanel'>
        <Tabs defaultActiveKey='account' onChange={this.onTabChange}>
        <TabPane tab={<div style={{ color: '#c0c0c0', padding: '0px 14px' }}>账户密码登陆</div>} key="account" >
    <div>
    <Input
    value={username}
    placeholder="用户名"
    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
    size='large'
    onChange={e => this.setState({ error: '', username: e.target.value })}
    />
    <Divider height={10} />
    <Input
    value={password}
    placeholder="密码" type='password'
    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
    size='large'
    onChange={e => this.setState({ error: '', password: e.target.value })}
    />
    <Divider height={20} />
    <Divider height={20} />
    <Button type='primary' size='large' style={{ width: '100%', borderRadius: 30 }} onClick={this.loginPwd} loading={logining}>{logining ? '登录中' : '登录'}</Button>
        </div>
        </TabPane>
  </Tabs>
    </div>
    {
      error && <Alert message="用户名或密码错误" type="error" closable
      afterClose={() => this.setState({ error: '' })}
      style={{ marginBottom: '120px', backgroundColor: 'red', color: 'white', paddingRight: '60px', border: 0 }}
      />
    }

  <div className='footer'>
        <div>帮助  隐私  条款</div>
    <div>Copyright  2018-2019 北京迈思发展科技有限责任公司</div>
    </div>

    </div>)
  }

    loginPwd = () => {
      let { username, password } = this.state
      if (!username || !password) {
        this.showError('请填写手机号和密码')
        return
      }

      this.setState({ logining: true }, () => {
        window.rmes.postJson('/api/v2/report/login', { username, password },
            (result) => {
              global.session = {
                username: this.state.username,
              }

              this.setState({ logining: false })
              window.localStorage.username = this.state.username
              window.sessionStorage.isLogin = 'true'
              window.location.hash = '/design'

            },
            error => {
              this.setState({ logining: false })
              this.showError(error)
            }
        )
      })
    }

    showError = (error) => {
      if (this.msgTimer) {
        clearTimeout(this.msgTimer)
      }

      this.setState({ error: error }, () => {
        this.msgTimer = setTimeout(() => this.setState({ error: '' }), 3000)
      })
    }
  }

    const Divider = (props) => {
      return <div style={{ height: props.height }} />
    }
