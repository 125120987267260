import React from 'react';
import { Badge } from 'antd';

import './ConfirmDialog';
import './Arrays';
import './Message';
import './Modal';
import './Collect';
import './Fetch';
import './Loading';
import {getTenantType} from './authority';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

export function genId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    }).toUpperCase();
}

export function genId32() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    }).toUpperCase();
}

export function getTabTitle(label, count, color) {

    return <Badge count={count} offset={[10, 0]} style={{ backgroundColor: color || '#666' }}>
        {label}
    </Badge>
}

export function getBillTabTitle(label, count, color) {
    const minWidth = !count ? 0 : 75
    return <Badge count={count} offset={[-12, 7]} style={{ backgroundColor: color || '#666' }}>
        <div style={{ minWidth }}>{label}</div>
    </Badge>
}

export function loginOut() {
    let user = getCookie('user');
    let username = getCookie('username');
    let domain = '/';
    if (!!user) {
        let userObj = JSON.parse(user);
        domain = userObj.tenantDetail.cookieDomain;
    }
    if(!!username){
        window.rmes.postJson("api/v2/report/logout", { "username": username }, () => {
            setCookie('isLogin', false, domain);
        });
    }  
}


export function delCookie(key) {
    var date = new Date();
    date.setTime(date.getTime() - 1);
    var delValue = getCookie(key);
    if (!!delValue) {
        document.cookie = key + '=' + delValue + ';expires=' + date.toGMTString();
    }
}


// 获取cookie
export function getCookie(key) {
    const name = key + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        const c = ca[i].trim();
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// 设置cookie,默认是30天
export function setCookie(key, value, domain) {
    const d = new Date();
    d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toGMTString();
    const path = "path=" + "/";
    let cookie = key + "=" + value + "; " + expires + ";" + path;
    if (!!domain) {
        const domainItem = "domain=" + domain;
        cookie += ";" + domainItem;
    }
    document.cookie = cookie;
}

// 关闭页面、切换页面时，执行注销操作
window.onunload = (event) => {
   
}

export function getLocationNoProject(bill) {
    // console.log(bill);
    return  getTenantType()==1?bill.customLocation.indexOf("\\")==-1?"":bill.customLocation.substring(bill.customLocation.indexOf("\\")+1):((!bill.infraLocation?"":bill.infraLocation+ "\\") + bill.elementType)
}

