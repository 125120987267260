import React, { PureComponent } from 'react';
import { Alert,Icon } from 'antd';
import {getTenantDetail} from '../../utils/authority';
import moment from 'moment';

export default class ExpireAlert extends PureComponent {
    constructor(params) {
        super(params);
        this.state = {

        }
    }

    render() {
        // let user = !!window.localStorage.user ? window.localStorage.user:getCookie('user');
        const tenantDetail =  getTenantDetail();
        const isSoonExpire = !!tenantDetail ? tenantDetail.isSoonExpire : false;
        const expireStatus = !!tenantDetail ? tenantDetail.expireStatus : 0;
        const expireDate = expireStatus > 1 ? moment(tenantDetail.endDate).format("YYYY-MM-DD") : null;
        const expireContent = !!tenantDetail ? tenantDetail.expireAlertContent : '';
        return (
            <div>
                {
                    (expireStatus === 2) &&
                    <Alert
                        // showIcon={false}
                        closeText={<Icon style={{color: '#ff0000',fontSize: 26}} type="close-circle" />}
                        type="warning"
                        style={{ textAlign: 'center', color: '#ff0000', fontSize: 23 }}
                        message={!!expireContent ? expireContent : "非常抱歉的通知您，您的账户将于[" + expireDate + "]过期，过期之后您将无法 登录使用，请您尽快联系售后人员续费!"}
                        banner
                        closable
                    />
                }
                {
                    (expireStatus === 3) &&
                    <Alert
                        // showIcon={false}
                        closeText={<Icon type="close-circle" />}
                        type="warning"
                        style={{ textAlign: 'center', color: '#ff0000', fontSize: 23 }}
                        message={!!expireContent ? expireContent : "非常抱歉的通知您，您的账户已于[" + expireDate + "]过期，请您尽快联系售后人员续费!若您已续费，请忽略此消息!"}
                        banner
                        closable
                    />
                }
            </div>

        )
    }
}