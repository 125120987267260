
import React from 'react';
import ReactDOM from 'react-dom';
import { Spin } from 'antd';

if (!window.rmes) window.rmes = {};

window.rmes.showLoading = function (params) {
    let root = document.getElementById('root');
    let loadingContainer = document.getElementById('loadingContainer');
    if (!loadingContainer) {
        loadingContainer = document.createElement("div");
        loadingContainer.id = 'loadingContainer';
    } else {
        loadingContainer.hidden = false;
    }
    // loadingContainer.style.cssText = "filter:Alpha(opacity=30); background-color:rgba(0,0,0,0.2);position:fixed;top:50px;width:133px;height:"+33+"px;z-index:10000;display:flex;justify-content:center;align-items:center";
    // root.parentNode.insertBefore(loadingContainer, root);
    loadingContainer.style.cssText = ";position:fixed;top:0px;width:100%;height:100%;display:flex;justify-content:center;align-items:center;z-index:999999;";
    root.appendChild(loadingContainer);
    let content = <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 13,
        padding: 13,
        filter: 'Alpha(opacity=70)',
        backgroundColor: 'rgba(0,0,0,0.7)',
        width: 'max-content',
        minWidth:166,
        height: 80,
        zIndex: 10000
    }}>
        <div style={{fontSize:17,color:'white'}}>
            <Spin />&nbsp;&nbsp;{!!params ? params.text : "加载中..."}
        </div>
    </div>
    // if (navigator.userAgent.indexOf("MSIE") > 0) {
    //     document.onkeydown = function () {
    //         if (27 === window.event.keyCode) {
    //             window.rmes.hideLoading();
    //         }
    //     }
    // } else {
    //     window.onkeydown = function () {
    //         if (27 === window.event.keyCode) {
    //             window.rmes.hideLoading();
    //         }
    //     }
    // }
    ReactDOM.render(content, loadingContainer);
}

window.rmes.hideLoading = function () {
    let loadingContainer = document.getElementById('loadingContainer');
    if (!!loadingContainer) {
        loadingContainer.hidden = true;
    }
}