import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Icon, Badge } from 'antd';

import store from '../../store/store.js';
import { getUser } from '../../utils/authority';

import * as Api from './Api';

import './index.less';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const NavLinkName = (props) => {
  let { menu } = props
  if (menu.target) {
    return <a href={props.menu.url} target={menu.target}>{props.menu.name}<RBadge count={props.badgeCount} /></a>
  } else if (menu.url) {
    return <NavLink to={props.menu.url} >{props.menu.name}<RBadge count={props.badgeCount} /></NavLink>
  } else {
    return <span>{props.menu.name}<RBadge count={props.badgeCount} /></span>
  }
}

const RBadge = (props) => {
  return <Badge style={{ boxShadow: '0 0 1px 1px #d9d9d9 inset', ...props.style }} count={props.count} offset={[3, -3]}>
    {props.children}
  </Badge>
}

export default class ToolbarMenu extends PureComponent {

  constructor(param) {
    super(param);
    this.state = {
      current: '',
      changed: 0,
      badgeMap: {}
    }
  }


  NavLinkName = NavLinkName;

  handleClick = (e) => {
    if (this.props.handleClick) {
      this.props.handleClick(e);
    }
  }

  componentDidMount() {
    // window.location.hash = '/ureport/designer'

  }

  render() {
    let { badgeMap } = this.state;
    return (<div className='r-menus' style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Menu style={{ width: '100%', display: 'flex', justifyContent: 'center' }} onClick={this.handleClick} selectedKeys={[this.props.current]} mode="horizontal" theme="dark" >
        {store.getMenus().map(m => {
          if ('base' !== m.key) {
            if (m.child && m.child.length > 0) {
              return <SubMenu className='r-sub-menus' style={'base' === m.key ? {} : {}} key={m.key ? m.key : Math.random() + ""} title={<span className="submenu-title-wrapper">{!!m.icon && <Icon type={m.icon} />}<NavLinkName menu={m} badgeCount={badgeMap[m.key]} /></span>}>
                {this.renderSubmenus(m.child)}
              </SubMenu>
            } else {
              return <Menu.Item key={m.key ? m.key : Math.random() + ""}><span className="submenu-title-wrapper">{!!m.icon && <Icon type={m.icon} />}<NavLinkName menu={m} badgeCount={badgeMap[m.key]} /></span></Menu.Item>
            }
          }
        })}
      </Menu>
    </div>
    )
  }

  // 渲染子菜单
  renderSubmenus = (items) => {
    let { badgeMap } = this.state;
    let menus = []
    if (!items) return menus

    let groups = []
    for (let item of items) {
      if (item.group && !groups.includes(item.group)) {
        groups.push(item.group)
      }
    }

    if (groups.length > 0) {
      for (let g of groups) {
        menus.push(this.renderGroupMenus(g, items.filter(item => item.group === g)))
      }

    } else {
      for (let item of items) {
        menus.push(<Menu.Item className='r-menus-item' key={item.key}><NavLinkName menu={item} badgeCount={badgeMap[item.key]} /></Menu.Item>)
      }
    }
    return menus
  }

  // 渲染分组
  renderGroupMenus = (group, items) => {
    let { badgeMap } = this.state;
    return <MenuItemGroup className="r-group-menus" key={group} title={group} >
      {items.map(item =>
        <Menu.Item key={item.key}>
          <NavLinkName menu={item} badgeCount={badgeMap[item.key]}/>
        </Menu.Item>)}
    </MenuItemGroup>
  }
}