/* 数组去重 */
export function unique (arr) {
    if ( Array.hasOwnProperty('from') ) {
        return Array.from(new Set(arr));
    }else{
        var n = {},r=[]; 
        for(var i = 0; i < arr.length; i++){
            if (!n[arr[i]]){
                n[arr[i]] = true; 
                r.push(arr[i]);
            }
        }
        return r;
    }
}

/* 添加一个元素或集合 */
export function add (arr, b) {
	if (Array.isArray(b)) {
    var newArr = arr.concat(b);
    return this.unique(newArr);
	} else {
		arr.push(b);
		return arr;
	}
}

/* 求两个数组交集 */
export function intersect (a, b) {
    var _this = this;
    a = this.unique(a);
    return this.map(a, function(o) {
        return _this.contains(b, o) ? o : null;
    });
}

/* 从一个数组删除一个元素或子数组 */
export function remove (arr, ele) {
    if (!arr) return

	if (Array.isArray(ele)) {
		for(let item of ele){
			arr = remove(arr, item)
		}
	} else {
        var index = arr.indexOf(ele);
        if(index > -1) {
            arr.splice(index, 1);
        }
        return arr;
	}
}

if (!window.rmes) window.rmes = {}
window.rmes.arrays = {
    unique, add, intersect, remove
}