import moment from 'moment';

import * as Util from './Utils';
import { getPermissionNameByKey, getPermissionFullNameByKey } from './permission';
import { getCookie } from './Utils';

import config from '../../package.json';

if (!window.rmes) {
    window.rmes = {}
}

const isReport = true;

// const url = 'http://localhost:9001/api/v1/report';
// const url = 'http://47.93.33.252:8888/api/v1/report';
const url = 'https://ra.rmes.cn/api/v1/report';

window.rmes.raModuleEnter = (module) => {
    window.rmes.ra('moduleEnter', {
        module
    })
}

window.rmes.raModuleLeave = (module) => {
    window.rmes.ra('moduleLeave', {
        module
    })
}

window.rmes.raLogin = () => {
    window.rmes.ra('login', {})
}

window.rmes.raLogout = () => {
    window.rmes.ra('logout', {})
}

function mergeMap(obj, src) {
    for (let k in src) {
        if (!obj[k]) {
            obj[k] = src[k];
        }
    }
    return obj
}

/**
 * module 模块编码 如有权限管控，则对应于云端权限编码（权限key值），如没有权限控制,则可以自定义
 * bthName 按钮名称
 * btnCode 按钮code
 * otherProps map对象,例：{billNum:'12312312',date:'20120409'}
 *            注意以下属性为保留属性，请不要在otherProps中使用：
 *            moduleName,tenant,user,session,browser,version
 */
window.rmes.raBtnClick = (module, btnName, btnCode,otherProps) => {
    let props = {
        module, btnName,
        btnCode: module.substring(module.length - 1) !== '/' ? (module + '/' + btnCode) : (module + btnCode)
    }
    if (!!otherProps) {
        mergeMap(props,otherProps);
    }
    window.rmes.ra('btnClick', props);
}

window.rmes.raBtnClickFilter = (btnModuleName, btnName,otherProps) => {
    window.rmes.raBtnClick(btnModuleName, !!btnName ? btnName : '筛选', 'filter',otherProps);
}

window.rmes.raBtnClickRefresh = (btnModuleName, btnName,otherProps) => {
    window.rmes.raBtnClick(btnModuleName, !!btnName ? btnName : '刷新', 'refresh',otherProps);
}

window.rmes.raBtnClickExport = (btnModuleName, btnName,otherProps) => {
    window.rmes.raBtnClick(btnModuleName, !!btnName ? btnName : '导出', 'export',otherProps);
}
 
window.rmes.raBtnClickProduce = {
    
    element: {
        viewOrderDetail: (btnModuleName, otherProps)=>{
            window.rmes.raBtnClick(btnModuleName, '查看订单详情', "viewOrderDetail", otherProps);
        },
        viewElementDetail: (btnModuleName, otherProps)=>{
            window.rmes.raBtnClick(btnModuleName, '匹配钢筋表', "viewElementDetail", otherProps);
        },
        assignTask:  (btnModuleName, otherProps)=>{
            window.rmes.raBtnClick(btnModuleName, '下发任务', "assignTask", otherProps);
        },
    },
    bill: {
        refresh: window.rmes.raBtnClickRefresh,
        export: window.rmes.raBtnClickExport,
        uploadTableBill: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '上传翻样料单', "billUpload");
        },
        editCustomLocation: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '编辑分部分项', "editCustomLocation");
        },
        viewElementNums: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '查看构件数量', "viewElementNums");
        },
        getMaterialEfficiency: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '试算', "getMaterialEfficiency");
        },
        newSolution: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '生成加工方案', "generateSolution");
        },
        viewTableBill: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '详情', "detail");
        },
        deleteTableBill: (btnModuleName, otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '删除', "delete", otherProps);
        },
        statTableBill: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '统计', "stat");
        },

        // add by shizp
        editRequireDate: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '编辑计划到场时间', "editRequireDate");
        },
        //通过审核
        approveBill: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '料单通过审核', "approveBill");
        },
        //作废料单
        invalidateBill: (btnModuleName, otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '作废料单', "invalidateBill",otherProps);
        },
        // preModifyBill: (btnModuleName) => {
        //     window.rmes.raBtnClick(btnModuleName, '预处理', "preModifyBill");
        // },
        batchSetRebarBarOrWire: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '批量设置棒材或线材', "batchSetRebarBarOrWire");
        },

        setRebarBarOrWire: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '设置棒材或线材', "setRebarBarOrWire");
        },

        deductOffset: (btnModuleName, type) => {
            window.rmes.raBtnClick(btnModuleName, '扣减弯曲延伸' + (type ? ("-" + type) : ""), "deductOffset");
        },
        // deductOffsetRound: (btnModuleName) =>{
        // 	window.rmes.raBtnClick(btnModuleName, '扣减弯曲延伸取整', "deductOffsetRound");
        // },
        deductOffsetClear: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '清除弯曲延伸', "deductOffsetClear");
        },
        setOffset: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '设置弯曲延伸', "setOffset");
        },
        modifyProduceLenght: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '修改实际生产长度', "modifyProduceLenght");
        },
        modifyProduceNum: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '修改实际生产根数', "modifyProduceNum");
        },
        recoverLengthAndNum: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '恢复原始长度及根数', "recoverLengthAndNum");
        },
        aps: (btnModuleName) => {//预处理完弹框、待排程列表
            window.rmes.raBtnClick(btnModuleName, '排程', "aps");
        },
        cancel: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '取消', 'cancel');
        },
    },
    solution: {
        viewSolutionTableBills: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '料单数量', 'viewSolutionTableBills');
        },
        newSolutionAps: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '排程', 'aps');
        },

        cancel: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '取消', 'cancel');
        },

        newDetail: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '详情', 'detail');
        },
        apsSolution: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '方案详情', 'solution');
        },
        finishSolution: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '方案详情', 'solution');
        },

        apsDetail: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '排程详情', 'detail');
        },
        apsFinish: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '加工完成', 'finish');
        },
        finishDetail: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '排程详情', 'detail');
        },


        // add by shizp
        agSort: (btnModuleName, col) => {
            window.rmes.raBtnClick(btnModuleName, '排序-' + col, 'agSort');
        },
        agFilter: (btnModuleName, col) => {
            window.rmes.raBtnClick(btnModuleName, '筛选-' + col, 'agFilter');
        },
        agRowGroup: (btnModuleName, cols) => {
            window.rmes.raBtnClick(btnModuleName, '列头拖拽-' + cols, 'agRowGroup');
        },
        agModifyProduceNum: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '选择本次加工根数', 'agModifyProduceNum');
        },
        agCardBundle: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '分捆编辑', 'agCardBundle');
        },
        agBatchSelectCuttingEquip: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '列头选择切断设备', 'agBatchSelectCuttingEquip');
        },
        agSelectCuttingEquip: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '选择切断设备', 'agSelectCuttingEquip');
        },
        agBatchSelectBendEquip: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '列头选择弯曲设备', 'agBatchSelectBendEquip');
        },
        agSelectBendEquip: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '选择弯曲设备', 'agSelectBendEquip');
        },
        agGroupSort: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '多选排序', 'agGroupSort');
        },

        billMergeChart: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '料单内钢筋合并', 'billMergeChart');
        },
        elementMergeChart: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '构件内钢筋合并', 'elementMergeChart');
        },
        stepPrevious: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '上一步', 'stepPrevious');
        },
        selectMaterail: (btnModuleName, type) => {
            window.rmes.raBtnClick(btnModuleName, '选择原材-' + type, 'selectMaterail');
        },
        // selectMaterail9:(btnModuleName)=>{
        // 	window.rmes.raBtnClick(btnModuleName, '选择原材9', 'selectMaterail9');
        // },
        modifyMaterailNum: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '修改原材数量', 'modifyMaterailNum');
        },
        printSln: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '打印加工单', 'printSln');
        },
        printSlnCard: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '打印料牌', 'printSlnCard');
        },
        searchSlnByBillCode: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '搜索料单编号', 'searchSlnByBillCode');
        },
        searchSlnByEquipName: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '搜索设备名称', 'searchSlnByEquipName');
        },
        searchSlnByBillName: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '搜索料单名称', 'searchSlnByBillName');
        },

        progress: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '加工进度查看', 'progress');
        },

        complete: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '加工完成', 'slnComplete');
        },
        slnCancel: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '取消', 'slnCancel');
        }

    },
    task: {
        refresh: window.rmes.raBtnClickRefresh,
        printSolution: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '方案', 'solution');
        },
        printCard: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '料牌', 'card');
        }
    }
}
window.rmes.raBtnClickDispatch = {
    un: {
        filter: window.rmes.raBtnClickFilter,
        refresh: window.rmes.raBtnClickRefresh,
        confirmDispatch: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '确认配送', 'confirmDispatch');
        },
        viewSolutionDetail: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '方案详情', 'viewSolutionDetail');
        }
    },
    wait: {
        filter: window.rmes.raBtnClickFilter,
        refresh: window.rmes.raBtnClickRefresh,
        newDispatchBill: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '新建配送单', 'newDispatchBill');
        },
        deleteWaitDispatch: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '删除', 'deleteWaitDispatch');
        },
        editWaitDispatchNumber: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '编辑配送数量', 'editWaitDispatchNumber');
        }
    },
    confirm: {
        filter: window.rmes.raBtnClickFilter,
        refresh: window.rmes.raBtnClickRefresh,
        export: (btnModuleName, type) => {
            window.rmes.raBtnClickExport(btnModuleName, "导出配送单列表-" + type);
        },
        deleteDispatchBill: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '删除配送单', 'deleteDispatchBill');
        },
        viewDispatchBill: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '查看配送单', 'viewDispatchBill');
        },
        editDispatchBill: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '编辑配送单', 'editDispatchBill');
        },
        printDispatchBill: (btnModuleName, type) => {
            window.rmes.raBtnClick(btnModuleName, '打印配送单-' + type, 'printDispatchBill');
        },
        completeDispatchBill: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '确认到场', 'completeDispatchBill');
        }
    },
    complete: {
        filter: window.rmes.raBtnClickFilter,
        refresh: window.rmes.raBtnClickRefresh,
        export: (btnModuleName, type) => {
            window.rmes.raBtnClickExport(btnModuleName, "导出配送单列表-" + type);
        },
        viewDispatchBill: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '查看配送单', 'deleteDispatchBill');
        },
        printDispatchBill: (btnModuleName, type) => {
            window.rmes.raBtnClick(btnModuleName, '打印配送单-' + type, 'printDispatchBill');
        }
    },
    printDispatchBill: (btnModuleName, type) => {
        window.rmes.raBtnClick(btnModuleName, '打印配送单-' + type, 'printDispatchBill');
    }
}

window.rmes.raBtnClickPurchaseOrder = {
    purchaseOrderList:{
        refresh: window.rmes.raBtnClickRefresh,
        newPurchaseOrder: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '新建订单', 'newPurchaseOrder');
        },
        editPurchaseOrder: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '变更订单', 'editPurchaseOrder',otherProps);
        },
        deletePurchaseOrder: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '作废订单', 'deletePurchaseOrder',otherProps);
        },
        viewPurchaseOrderDetail: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '查看订单详情', 'viewPurchaseOrderDetail',otherProps);
        },
        viewPurchaseOrderProgress: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '查看订单流程', 'viewPurchaseOrderProgress',otherProps);
        },
        viewPurchaseOrderProgressPoint: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '查看订单流程节点详情', 'viewPurchaseOrderProgressPoint',otherProps);
        },
        editRejectedPurchaseOrder: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '订单审核驳回，重新提交', 'editRejectedPurchaseOrder',otherProps);
        },
        filterTodayPurchaseOrder: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '筛选今天订单', 'filterTodayPurchaseOrder');
        },
        filterThisWeekPurchaseOrder: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '筛选本周订单', 'filterThisWeekPurchaseOrder');
        },
        filterPickDatesPurchaseOrder: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '筛选日期范围订单', 'filterPickDatesPurchaseOrder');
        },
        expandAllPurchaseOrder: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '展开全部订单', 'expandAllPurchaseOrder');
        },
        foldAllPurchaseOrder: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '折叠全部订单', 'foldAllPurchaseOrder');
        },
        printPurchaseOrder:(btnModuleName) =>{
            window.rmes.raBtnClick(btnModuleName, '打印订单', 'printPurchaseOrder');
        }
    },
    myAduitOrder:{
        auditPassPurchaseOrder: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '订单审核通过', 'auditPassPurchaseOrder',otherProps);
        },
        auditRejectPurchaseOrder: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '订单审核驳回', 'auditRejectPurchaseOrder',otherProps);
        },
        viewAuditedPurchaseOrder: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '已审核订单详情', 'viewAuditedPurchaseOrder',otherProps);
        },
        viewAuditedRejectPurchaseOrder: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '已审核驳回订单详情查看', 'viewAuditedRejectPurchaseOrder',otherProps);
        },
    },
    myAduitTable:{
        auditPassTable: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '料单审核通过', 'auditPassTable',otherProps);
        },
        auditRejectTable: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '料单审核驳回', 'auditRejectTable',otherProps);
        },
        viewAuditedTable: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '已审核料单详情', 'viewAuditedTable',otherProps);
        },
        viewAuditedRejectTable: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '已审核驳回料单详情查看', 'viewAuditedRejectTable',otherProps);
        },
    },
    myAduitDispatch:{
        auditPassDispatch: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '配送单审核通过', 'auditPassDispatch',otherProps);
        },
        auditRejectDispatch: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '配送单审核驳回', 'auditRejectDispatch',otherProps);
        },
        viewAuditedDispatch: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '已审核配送单详情', 'viewAuditedDispatch',otherProps);
        },
        viewAuditedRejectDispatch: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '已审核驳回配送单详情查看', 'viewAuditedRejectDispatch',otherProps);
        },
    },
    purchaseOrderList_c:{
        viewPurchaseOrderProgress: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '查看订单流程', 'viewPurchaseOrderProgress',otherProps);
        },
        viewPurchaseOrderProgressPoint: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '查看订单流程节点详情', 'viewPurchaseOrderProgressPoint',otherProps);
        },
        filterTodayPurchaseOrder: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '筛选今天订单', 'filterTodayPurchaseOrder');
        },
        filterThisWeekPurchaseOrder: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '筛选本周订单', 'filterThisWeekPurchaseOrder');
        },
        filterPickDatesPurchaseOrder: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '筛选日期范围订单', 'filterPickDatesPurchaseOrder');
        },
    },
    uploadBill:{
        uploadBill: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '上传料单', 'uploadBill');
        },
    },
    assignBillList:{
        viewBillDetail: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '料单详情', 'viewBillDetail',otherProps);
        },
        assignToFactory: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '下发至加工厂', 'assignToFactory',otherProps);
        },
        deleteBill: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '作废料单', 'deleteBill',otherProps);
        },
    },
}

window.rmes.raBtnClickDispatch3 = {
    un: {
        filter: window.rmes.raBtnClickFilter,
        refresh: window.rmes.raBtnClickRefresh,
        newDispatchBill: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '确认配送', 'newDispatchBill');
        },
        viewDispatchTableChart: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '查看料单可配送钢筋行', 'viewDispatchTableChart',otherProps);
        },
    },
    wait: {
        filter: window.rmes.raBtnClickFilter,
        refresh: window.rmes.raBtnClickRefresh,
        newDispatchBill: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '新建配送单', 'newDispatchBill');
        },
        deleteWaitDispatch: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '删除', 'deleteWaitDispatch');
        },
        editWaitDispatchNumber: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '编辑配送数量', 'editWaitDispatchNumber');
        }
    },
    dispatchList:{
        viewDispatchBill: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '查看配送单', 'viewDispatchBill',otherProps);
        },
        editDispatchBill: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '编辑配送单', 'editDispatchBill',otherProps);
        },
        deleteDispatchBill: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '作废配送单', 'deleteDispatchBill',otherProps);
        },
        completeDispatchBill: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '确认出厂', 'completeDispatchBill',otherProps);
        },
        cancelDispatchBill: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '取消出厂', 'cancelDispatchBill',otherProps);
        },
        confirmDispatchBill: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '确认到场', 'confirmDispatchBill',otherProps);
        },
        printDispatchBill: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '打印配送单', 'printDispatchBill',otherProps);
        },
        exportDispatchBill: (btnModuleName,type,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '导出配送单-'+type, 'exportDispatchBill',otherProps);
        },
        viewDispatchDeleteReason: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '查看配送单作废原因', 'viewDispatchDeleteReason',otherProps);
        },
        viewDispatchRejectReason: (btnModuleName,otherProps) => {
            window.rmes.raBtnClick(btnModuleName, '查看配送单驳回原因', 'viewDispatchRejectReason',otherProps);
        },
    },
    export:(btnModuleName,type) => {
        window.rmes.raBtnClick(btnModuleName, '导出配送列表-'+type, 'export')
    }
}

window.rmes.raBtnClickResidue = {
    in:{
        new: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '新建余料入库', 'new');
        },
    },
    out:{
        new: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '新建余料消耗', 'new');
        },
    },
    check:{
        new: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '新建余料盘点', 'create');
        },
        view: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '盘点单详情', 'detail');
        },
        print: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '打印盘点单', 'print');
        },
        export: (btnModuleName,type) => {
            window.rmes.raBtnClick(btnModuleName, '导出盘点单-'+type, 'export');
        },
    },
    stock:{
        refresh: window.rmes.raBtnClickRefresh,
        exportResidueDetail: (btnModuleName,type) => {
            window.rmes.raBtnClick(btnModuleName, '导出余料明细表-'+type, 'exportResidueDetail');
        },
        exportResidueSum: (btnModuleName,type) => {
            window.rmes.raBtnClick(btnModuleName, '导出余料汇总表-'+type, 'exportResidueSum');
        },
        downloadResidueStockQrCode: (btnModuleName) => {
            window.rmes.raBtnClick(btnModuleName, '下载余料二维码', 'downloadResidueStockQrCode');
        },
    }
}

window.rmes.ra = (event, props) => {
    if (!isReport) {
        return;
    }
    if (!!props.module) {
        props.moduleName = getPermissionFullNameByKey(props.module) || getPermissionFullNameByKey(props.module.substring(0, props.module.length - 1));
    }
    let user = getCookie('user');
    if (!!user) {
        let userObject = JSON.parse(user);
        props.tenant = userObject.tenant;
        props.user = userObject.name;
        props.session = getCookie('JSESSIONID') || sessionStorage.getItem('session');
    }
    props.browser = navigator.userAgent;
    props.version = config.version;
    let eventContent = {
        id: Util.genId32(),
        platform: 'rmes_web',
        event,
        time: moment().format('YYYYMMDDHHmmss.SSS'),
        session: sessionStorage.getItem('session'),
        properties: props
    }

    window.rmes.postJson(url, eventContent, (data) => { }, (data) => { })
}