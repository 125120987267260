import {getPermission,isAdministrator}  from './authority'

export function checkKey(key) {
  if(isAdministrator()){
    return true;
  }
  const userPermission = getPermission().find(permission=>
    permission.key === key
  )
  return !!userPermission
}

export function getPermissions(key) {
  const userPermission = getPermission().filter(permission=>
    permission.key.indexOf(key) > -1
  )
  return userPermission
}

export function getPermissionNameByKey(key){
  const p = getPermission().filter(permission =>{
    return permission.key === key;
  });

  return (!!p && p.length>0) ? p[0].name : '';
}

export function getPermissionFullNameByKey(key){
  
  if(!key || key.length === 0){
    return '';
  }
  let ps = key.split('\/');
  let fullKey = ps[0];
  let fullName =getPermissionNameByKey(fullKey);
  if(ps.length > 1){
    ps.forEach((p,i)=>{
      if(i>0 && !!p ){
        fullKey += '/'+p;
        let subName = getPermissionNameByKey(fullKey);
        if(!!subName){
          fullName += '/'+subName;
        }
      }
    })
  }
  return fullName;
}