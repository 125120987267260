import React, { Component } from 'react';
const devUrl = 'http://localhost:9002'
const tenantVersionUrl = window.location.hostname;
export default class Design extends Component {
    state = {
        url:'/ureport/designer',
        logining: false
    }
    render() {
        let url = this.state.url
        if(tenantVersionUrl.indexOf("localhost")>=0) {
            url = devUrl + url;
        } else {
            url = window.location.protocol+"//"+window.location.host+url;
        }
       return (
        <iframe
        style={{ width: '100%', height: '100%'}}
        src={url}
        scrolling='auto'
        seamless='seamless'
        onLoad={this.onIframeLoad}
        onError={this.onIframeError}
        />)
    }
    onIframeLoad = () => {
        this.setState({
            loading: false
        })

    }

    showError = (error) => {
        if (this.msgTimer) {
            clearTimeout(this.msgTimer)
        }

        this.setState({ error: error }, () => {
            this.msgTimer = setTimeout(() => this.setState({ error: '' }), 3000)
        })
    }

    onIframeError = () => {
        window.rmes.message.warn("加载错误")
    }
}