// use localStorage to store the authority info, which might be sent from server in actual project.
import {getCookie} from './Utils';

export function getAuthority() {

  return !!getUser() ? getUser().currentAuthority : 'admin';
}

export function getRole() {
  if (!!getUser()) {
    return getUser().currentRole
  }
}

export function isAdministrator() {
  if (!!getUser()) {
    return getUser().currentRole === "admin"
  }
}

export function getUserName() {
  if (!!getUser()) {
    return getUser().name
  }
}

export function getTenantType() {
  if (!!getUser()) {
    return getUser().tenantType
  }
}

export function getTenant() {
  if (!!getUser()) {
    return getUser().tenant
  }
}

export function setAuthority(currentAuthority) {
  localStorage.setItem('currentAuthority', currentAuthority);
}

export function setUser(user) {
  localStorage.setItem('user', user);
}

export function getPermission() {
  let user =  JSON.parse(localStorage.getItem('user'));
  if (!!user) {
    let permissionList = user.permissions;
    return !!permissionList ? permissionList : []

  }
  return [];
}

export function getTenantDetail(){
  let user = getUser();
  return !!user ? user.tenantDetail : null;
}

export function getUser() {
  return JSON.parse(getCookie('user')) || JSON.parse(localStorage.getItem('user'))
  // return global.session;
}

export function getTenantVersion() {
  return getCookie('rversion')
}

