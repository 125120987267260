import React, { Component } from 'react';
import LogoImage from '../../assets/images/logo-r.png';
import {getUser} from '../../utils/authority';

const styles = {
	main : {
		display:'flex',
		color:'#e8e8e8',
		alignItems:'center',
		fontSize:'22px',
		paddingLeft:'8px',
    height:'50px',
  },
  logo:{
    // width:80,
    height:33,
    alignSelf:'flex-end',
    marginBottom: 8
  },
  title:{
    paddingLeft:12, 
    paddingTop:0,
    fontSize:'0.8em'
  }
}

export default class Logo extends Component {
  render() {
    let logo = LogoImage;
    let title = 'RMES钢筋管理平台';
    let user = getUser();
    if(!!user && !!user.tenantDetail && !!user.tenantDetail.config && !!user.tenantDetail.config.menu){
      let menuConfig = JSON.parse(user.tenantDetail.config.menu);
      logo = menuConfig.logo;
      title = menuConfig.title;
    }
    return (
      <div style={styles.main}>
        <img src={logo} style={styles.logo} />
      	<div style={styles.title}>{title}</div>
      </div>
    )
  }
}