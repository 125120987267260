import React from 'react';
import 'url-search-params-polyfill';
import { progressBarFetch, setOriginalFetch } from 'react-fetch-progressbar';

import FetchErrorPng from '../assets/images/fetch_error.png';
import SessionTimeoutPng from '../assets/images/session_timeout.png';
import * as utils from './Utils';

require('es6-promise').polyfill();

setOriginalFetch(window.fetch);
window.fetch = progressBarFetch;

if (!window.rmes) {
  window.rmes = {}
}

window.SERVER = '';

function errorMessage(err, handler) {
  if (handler) {
    handler(err)
  } else if (defaultErrorHandler) {
    defaultErrorHandler(err)
  } else if (window.error) {
    window.error(err)
  } else {
    window.alert(err)
  }
}

function warnMessage(err, handler) {
  if (handler) {
    handler(err)
  } else if (defaultWarnHandler) {
    defaultWarnHandler(err)
  } else if (window.warn) {
    window.warn(err)
  } else {
    window.alert(err)
  }
}

const handleError = function (error, fail, sessionTimeout) {
  if (error instanceof Promise) {
    error.then(json => {
      if (json.message === 'ERROR_NOT_LOGIN') {
        if (sessionTimeout) {
          sessionTimeout()
        } else if (defaultSessionTimeout) {
          defaultSessionTimeout();
        } else {
          errorMessage('未登录或会话超时', fail)
          setTimeout(() => {
            window.location = window.location;
          }, 1000)
        }
      } else {
        //运行时异常 统一认为是业务提醒而不是错误
        if(json.exception === 'java.lang.RuntimeException'){
          warnMessage(json.message || json.errorMsg, fail)
        }else{
          errorMessage(json.message || json.errorMsg, fail)
        }
      }
    })
  } else {

    if (fail != null) {
      fail(error)

    } else {

      if ((error + '').includes('Failed to fetch')) {
        error = '无法连接服务器,请确认服务器已开启或网络通畅';
        document.write('<div style="text-align:center; width:100%; margin-top:200px; padding: 30px 0px; background-color: yellow; font-size: 24px;">无法连接服务器,请确认服务器已开启或网络通畅</div>')
      } else {
        console.log(error)
        errorMessage(error, fail)
      }
    }
  }
}

const preHandler = function (resp, succ, fail, responseType) {
  if (resp.ok) {
    if (responseType === 'blob' && succ) {
      resp.blob().then(
        blob => succ(blob)
      )
    } else {
      return resp.text()
    }
  } else if (resp.status === 500) {
    throw resp.json()
  } else {
    throw resp
  }
}

const textHanlder = function (text, succ, fail, responseType) {
  if (responseType === 'text') {
    if (succ) succ(text)
  } else if (text) {
    if (succ) succ(JSON.parse(text))
  } else {
    if (succ) succ()
  }
}

const defaultErrorHandler = function (err) {
  let errContent = getErrContent(err);
  window.rmes.modal.error({
    title: '错误',
    content: (<div style={{ marginLeft: -32, fontWeight: 'bold', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <img src={FetchErrorPng} />
      <div>{errContent}</div>
    </div>)
  })
}

const defaultWarnHandler = function (err) {
  let errContent = getErrContent(err);
  window.rmes.modal.warn({
    title: '提示',
    content: (<div style={{ marginLeft: -32, fontWeight: 'bold', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <img src={FetchErrorPng} />
      <div>{errContent}</div>
    </div>)
  })
}

function getErrContent(err){
  let errContent = '';
  if (!!err) {
    if (!!err.status) {
      if (err.status === 404) {
        errContent = err.url + " " + err.statusText;
      } else {
        errContent = err.statusText;
      }
    } else if (err instanceof Error) {
      errContent = err.message;
    }
    else if (err instanceof String || typeof err === 'string') {
      //sql 错误，不直接暴露给用户
      if (err.indexOf('StatementCallback') > -1 || !err) {
        errContent = '出现错误';
      } else {
        errContent = err;
      }
    }
  }else{
    errContent = '出现错误';
  }
  return errContent;
}

let isAlreadyWarnSessionTimeout = false;
window.rmes.defaultErrorHandler = defaultErrorHandler;
window.rmes.defaultWarnHandler = defaultWarnHandler;
const defaultSessionTimeout = function () {
  if (isAlreadyWarnSessionTimeout) {
    return;
  }
  setTimeout(() => {
    window.sessionStorage.isLogin = 'false';
    utils.loginOut();
    window.location.hash = '/login';
    isAlreadyWarnSessionTimeout = false;
    modal.destroy();
  }, 3000);
  var modal = window.rmes.modal.warn({
    title: '提醒',
    content: (<div style={{ marginLeft: -32, fontWeight: 'bold', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <img src={SessionTimeoutPng} />
      <div>未登录或会话超时,3秒后自动跳转...</div>
    </div>),
    onOk() {
      window.sessionStorage.isLogin = 'false';
      utils.loginOut();
      window.location.hash = '/login';
      isAlreadyWarnSessionTimeout = false;
    }
  })
  isAlreadyWarnSessionTimeout = true;
}

window.rmes.get = function (url, succ, fail, responseType, sessionTimeout) {
  fetch(window.SERVER + url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
    }
  })
    .then(resp => preHandler(resp, succ, fail, responseType))
    .then(text => textHanlder(text, succ, fail, responseType))
    .catch(error => handleError(error, fail, sessionTimeout))
}

window.rmes.delete = function (url, succ, fail, responseType, sessionTimeout) {
  fetch(window.SERVER + url, {
    method: 'DELETE',
    credentials: 'include'
  })
    .then(resp => preHandler(resp, succ, fail, responseType))
    .then(text => textHanlder(text, succ, fail, responseType))
    .catch(error => handleError(error, fail, sessionTimeout))
}

window.rmes.post = function (url, reqBody, succ, fail, requestType, responseType, sessionTimeout) {

  let body, reqType;

  if (requestType === 'json') {
    // body = JSON.stringify(reqBody)
    body = reqBody;
    reqType = 'json'

  } else {
    if (reqBody instanceof FormData) {
      body = reqBody;
    } else {
      body = new URLSearchParams();
      for (let k in reqBody) {
        if (reqBody[k] != null) {
          body.append(k, reqBody[k])
        }
      }
    }
    reqType = 'x-www-form-urlencoded'
  }

  fetch(window.SERVER + url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/' + reqType + ';charset=utf-8',
      'accept-charset': 'UTF-8',
    },
    body: body,
  })
    .then(resp => preHandler(resp, succ, fail, responseType))
    .then(text => textHanlder(text, succ, fail, responseType))
    .catch(error => handleError(error, fail, sessionTimeout))
}

window.rmes.postForm = function (url, form, succ, fail) {
  window.rmes.post(url, form, succ, fail, 'form')
}

window.rmes.postJson = function (url, json, succ, fail) {
  let reqJson = JSON.stringify(json);
  window.rmes.post(url, reqJson, succ, fail, 'json')
}

window.rmes.upload = function (url, form, succ, fail, responseType, sessionTimeout) {

  let body;

  if (form instanceof FormData) {
    body = form
  } else {
    body = new URLSearchParams()
    for (let k in form) {
      body.append(k, form[k])
    }
  }

  fetch(window.SERVER + url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'accept-charset': 'UTF-8',
    },
    body: body,
  })
    .then(resp => preHandler(resp, succ, fail, responseType))
    .then(text => textHanlder(text, succ, fail, responseType))
    .catch(error => handleError(error, fail, sessionTimeout))
}