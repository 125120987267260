import React, { Component } from 'react';
import ToolbarMenu from './ToolbarMenu';
import UserToolbar from '../UserToolbar';
import Logo from '../Logo';
import store from '../../store/store.js';
import ExpireAlert from './ExpireAlert';

// import * as userApi from "../../pages/setting/user/Api";

const styles = {
	main: {
		height: '100%',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
	},
	toolbar: {
		height: '50px',
		padding: 8,
		display: 'flex',
		alignItems: 'center',
	},
	menubar: {
		height: '32px',
		display: 'flex',
		justifyContent: 'center',
		border: '2x solid #444',
	},
	container: {
		display: 'flex',
		position: 'absolute',
		left: 0, top: 50, right: 0, bottom: 0,
		padding: '8px',
	}
}

export default class MainHor extends Component {

	state = {
		current: ''
	}

	childMenuKeyUrlMap = store.getUrlKeyMap

	componentDidMount() {
		this.setMenuKey();
		window.onhashchange = this.onhashchange;
		// this.initData();
	}

	componentWillUnmount() {
		window.onhashchange = null;
	}

	initData = () => {
		this.initStorage();
		this.initUser();
	}


	// initUser = () => {
	// 	userApi.userInfo(data => {
	// 		let user = JSON.stringify(data);
	// 		window.localStorage.user = user;
	// 		delete window.localStorage.username;
	// 	})
	// }

	initStorage = () => {
		window.rmes.get("/api/v1/storage/original/stock/getConfig", (data) => {
			if (!!data) {
				window.localStorage.storageConfig = data.value;
			} else {
				localStorage.removeItem("storageConfig");
			}
		})
		
	}

	onhashchange = () => {
		this.setMenuKey();
	}

	setMenuKey = () => {
		// let hash = window.location.hash;
		// let menukey = (!!hash && Object.keys(this.childMenuKeyUrlMap).length > 0) ? this.childMenuKeyUrlMap[hash.substring(hash.indexOf('#') + 1)] : 'index';
		// this.setState({
		// 	current: menukey
		// })
	}

	handleClick = e => {
		//离开模块埋点
		if (!!this.state.current) {
			window.rmes.raModuleLeave(this.state.current);
		}

		this.setState({
			current: e.key,
		});

		//埋点
		window.rmes.raModuleEnter(e.key);
	};

	render() {
		return (
			<div style={styles.main}>
				<ExpireAlert />
				<div className='header' style={styles.toolbar}>
					<Logo />
					<div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', maxWidth: window.innerWidth < 800 ? window.innerWidth * 0.65 : window.innerWidth * 0.75 }}>
						<ToolbarMenu handleClick={this.handleClick} current={this.state.current} />
					</div>
					<UserToolbar handleClick={this.handleClick} current={this.state.current} />
				</div>
				<div className='container' style={styles.container}>
					{this.props.children}
				</div>
			</div>
		)
	}
}